import React from "react";
import './MainContent.css';
import HeaderSection from "../HeaderSection/HeaderSection";
import FooterSection from "../FooterSection/FooterSection";
import { Image, Nav, Row, Col, Container } from "react-bootstrap";
import compassImage from '../../assets/images/compass 2.png';
import s100Image from '../../assets/images/new1.png';
import VeroBoatS51 from '../../assets/images/Vero Boat_S5 1.png';
import Surveys8 from '../../assets/images/Survey_s8.png';
import { Link } from "react-router-dom";
const MainContent = () => {
    return(
        <>        
            <HeaderSection/>
            <main>
            <div className="bg_banner_sec_2">
              <Container className="homeContainer">
                <Row className="mt-5">
                    <Col sm={6} className="py-0 px-4">
                      <div className="intro py-5 my-0 my-md-5 margin_0">
                        <h1 className="intro_heading py-2">International Centre of Geospatial sciences</h1>
                        <p className="intro_para pb-2">IIC Academy is the training arm of IIC Technologies, which brings together over two decades of global expertise in geospatial projects and services.</p>
                        <div className="mt-4 mb-2">
                          <a href="#contactUs" className="redbtn_see">Contact Us</a>
                        </div>
                      </div>
                    </Col>
                    <Col sm={6} className="py-0 px-4">
                      <div className="intro py-0 py-md-5 mt-0 mb-5 my-md-5">
                        <Image className="mx-auto py-2 mb-0 img-fluid" src={compassImage} alt="" />
                      </div>
                    </Col>
                  </Row>
              </Container>
            </div>
            <section className="mb-0 pb-0 mb-sm-5 pb-sm-5">
              <Container className="homeContainer">
                <Row className="pt-4 justify-content-center">
                  <div className="col-12 col-md-6 d-flex align-items-center">
                    <div className="py-4">
                      <h2 className="mb-3 black_text font-weight-bold text-center">Current Programs</h2>
                      <p className="black_text text-center"> The Academy’s industry-focused programs are flexible, customizable and range from short term fundamentals to long term advanced levels. Using best practices, the academy empowers its learners to be industry-ready and succeed in the workplace. </p>
                    </div>
                  </div>
                </Row>
                <Row className="py-4 justify-content-around">
                  <div className="col-12 col-lg-5 d-flex align-items-center bg_navy rounded mb-4" style={{position: 'relative'}}>
                    <div className="px-4 py-5 col-12">
                      <div className="mb-4 w-100 row-4" style={{height:'350px'}}>
                        <Image className="h-100 w-100 mx-auto py-2 mb-0" src={VeroBoatS51} alt="" style={{objectFit:'cover'}} />
                      </div>
                      <h2 className="mb-3 white_text ">S-5B Hydrographic Surveying Program </h2>
                      <p className="white_text pr-2">The purpose of IIC Academy’s S-5 Category B program is to prepare candidates with the theoretical and practical competencies necessary to carry out the planning and implementation of hydrographic surveys effectively.</p>
                      <div className="mt-4 mb-2">
                        <button className="redbtn_see"><Nav.Link as={Link} to="/S5BProgram">Learn More</Nav.Link></button>
                      </div>
                    </div>
                    <div className="position-absolute bg_lightblue px-3 py-2 " style={{right:'0', top:'300px'}}>
                      <h4 className="white_text m-0">Commencing Sept. 2<sup>nd</sup>, 2024 </h4>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5 d-flex align-items-center bg_navy rounded mb-4" style={{position: 'relative'}}>
                    <div className="px-4 py-5 col-12">
                      <div className="mb-4 w-100 row-4 " style={{height:'350px'}}>
                        <Image className="h-100 w-100 mx-auto py-2 mb-0" src={Surveys8} alt="" style={{objectFit:'cover'}} />
                      </div>
                      <h2 className="mb-3 white_text">S-8B Marine Geospatial Information Program</h2>
                      <p className="white_text pr-2">The purpose of the IIC S-8 Category B program is to prepare candidates with the theoretical and practical competencies necessary to effectively carry out the planning and implementation of nautical chart production.</p>
                      <div className="mt-4 mb-2">
                        <button className="redbtn_see"><Nav.Link as={Link} to="/S8BProgram">Learn More</Nav.Link></button>
                      </div>
                    </div>
                    <div className="position-absolute bg_lightblue px-3 py-2 " style={{right:'0', top:'300px'}}>
                      <h4 className="white_text m-0">Commencing Sept. 9<sup>th</sup>, 2024 </h4>
                    </div>
                  </div>
                </Row>
              </Container>
            </section>
            <section className="bg_banner_sec_3">
            <Container className="homeContainer">
                <Row className="my-4">
                    <Col sm={9} className="py-0 px-4">
                      <div className="intro my-0 my-md-0 margin_0">
                        <h1 className="intro_heading py-2">Are You Ready for S-100?</h1>
                        <p className="intro_para pb-2">By 2025 Hydrographic Offices and National Agencies worldwide are expected to deliver regular native production of S-101 ENCs. This will mark the start of the transition to the S-100 data model.</p>
                        <div className="mt-4 mb-2">
                          <a href="https://s100.iictechnologies.com/" className="redbtn_see">Get Started</a>
                        </div>
                      </div>
                    </Col>
                    <Col sm={3} className="py-0 px-4">
                      <div className="intro py-0 py-md-0 mt-0 mb-0 my-md-0">
                        <Image className="mx-auto py-2 mb-0 img-fluid" src={s100Image} alt="" />
                      </div>
                    </Col>
                  </Row>
              </Container>
            </section>
          </main>
            <FooterSection/>
        </>
    )
}
export default MainContent;