import React from "react";
import S8BHeaderSection from "./S8BHeaderSection";
import S8BFooterSection from "./S8BFooterSection";
import newsDesk from '../../assets/images/nesw-dark.png';
import { Container, Image, Row, Col, Nav, Tab, Accordion } from "react-bootstrap";
import '../../assets/css/program_breakdown_page.css';

const S8BProgramBreakdown = () => {
    return(
        <>
            <S8BHeaderSection/>
            <Container fluid>
            <Row>
		<Col>
        <Container>
			<Row className="my-4">
				<Col sm={7} className="d-flex align-items-center">
					<div className="my-4 py-3 black_text">
						<h1 className="all_main_heads">Program Breakdown</h1>
                        <p class="py-3">The programme is designed to be delivered 
                        online, with the theoretical and practical components 
                        carried out via remote learning and instructor led lectures,
                         focused on developing a student’s knowledge and skills in the 
                         production of nautical charts. The programme completes with the 
                         student’s proficiency being assessed through a Comprehensive 
                         Cartographic Project.</p>
                         <h5 class="red_text redfont">Sept. 9, 2024 – May 2025</h5>
                         <p>Participants will have an elapsed period of 30 weeks to complete the 22 week program</p>
					</div>
				</Col>
				
				<Col sm={5} className="d-flex align-items-center justify-content-center">
					<Image src={newsDesk} className="img-fluid"></Image>
				</Col>
			</Row>
        </Container>
	<Container className="mb-5">
    <Tab.Container id="left-tabs-example" defaultActiveKey="theory">
      <Row>
        <Col>
        <Nav variant="tabs" fill>
            <Nav.Item className="w-50">
              <Nav.Link eventKey="theory" className="p-sm-4 p-xs-1 border-0 tabsClass">Theory + Practicals</Nav.Link>
            </Nav.Item>
            <Nav.Item className="w-50">
              <Nav.Link eventKey="CCP" className="p-sm-4 p-xs-1 border-0 tabsClass">Comprehensive Cartographic Project </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="theory" className="bg-dark-blue">
                    <p className="text-white my-4 w-80">The IIC Academy has integrated the theoretical component of the program under a Learning Management System platform, which will allow participants to follow the modules and study from the comfort of their Homes or offices only requiring a computer and internet access.</p>
                    <Accordion>
                    <Accordion.Item className="my-4 border-0" eventKey="0">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-1</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0 d-flex align-items-center">
                                        <h4 className="mb-0 tab_head">Foundations of Marine Geospatial Information</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the introduction to the program. A brief history of nautical charting. Overview of charting in the world today.</p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="1">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-2</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Data Assessment and Compilation</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the essential aspects of nautical chart production, with details about the S-57 Object Catalogue, data sources and production tools available in the market, together with an insight on common industry practices when it comes to chart production.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="2">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-3</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Production and Validation</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the important aspects of nautical chart production, covering aspects like chart design, quality controls on source data, sounding selection and chart validation.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="3">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-4</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Marine Environment</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the elements of Geology, Geophysics, Oceanography, Meterology and Law of the Sea.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="4">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-5</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Marine Spatial Data Infrastructures</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the concepts, evolution, practical deployments, role of MSDI in marine national and regional development.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                    <Accordion.Item className="my-4 border-0" eventKey="5">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">Module-6</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Remote Sensing</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p>This module covers the remote sensing concepts, principles, and practice.
                                        </p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                 
                    </Accordion>  
            </Tab.Pane>
            <Tab.Pane eventKey="CCP" className="bg-dark-blue">  
                <Accordion>
                    <Accordion.Item className="my-4 border-0" eventKey="0">
                        <Accordion.Header className="p-0">
                            <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-sm-block">
                                        <h4 className="mb-0 tab_head text-white">4 weeks</h4>
                                    </Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <h4 className="mb-0 tab_head">Comprehensive Cartographic Project</h4>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                        <Container>
                                <Row>
                                    <Col xs={4} sm={3} className="bg-blue p-0 tab_width d-none d-sm-block tab_width">&nbsp;</Col>
                                    <Col xs={8} sm={9} className="p-0">
                                        <div className="p-4"><p> To complete the program, students will be tasked with a Comprehensive 
                                            Cartographic Project, in which they will have to plan and compile a 
                                            chart and execute the proper validation checks to guarantee its integrity and usability.</p></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Accordion.Body>
                        
                    </Accordion.Item>
                   
                </Accordion>  
            </Tab.Pane>
          </Tab.Content>
		</Col>
	</Row>
    </Tab.Container>
    </Container>
    </Col>
	</Row>	
	<div className="lodaer_mask" id="loaderMask">
		<div className="lds-facebook"><div></div><div></div><div></div></div>
	</div>
    </Container>
            <S8BFooterSection/>
        </>
    )
}
export default S8BProgramBreakdown;