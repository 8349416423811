import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainContent from './Components/MainContentSection/MainContent';

import S5BOverviewPage from './Components/S5BProgram/S5BOverview';
import S5BApplyNow from './Components/S5BProgram/S5BApplyNow';
import S5BProgramBreakdown from './Components/S5BProgram/S5BProgramBreakdown';

import S8BOverviewPage from './Components/S8BProgram/S8BOverview';
import S8BApplyNow from './Components/S8BProgram/S8BApplyNow';
import S8BProgramBreakdown from './Components/S8BProgram/S8BProgramBreakdown';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/S5BProgram" element={<S5BOverviewPage />} />
          <Route path="/S5BapplyNow" element={<S5BApplyNow />} />
          <Route path="/S5BprogramBreakdown" element={<S5BProgramBreakdown />} />
          <Route path="/S8BProgram" element={<S8BOverviewPage />} />
          <Route path="/S8BapplyNow" element={<S8BApplyNow />} />
          <Route path="/S8BprogramBreakdown" element={<S8BProgramBreakdown />} />
        </Routes>
      </Router>
      <ToastContainer/>
    </>
  );
}

export default App;
