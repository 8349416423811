import React from "react";
import { Link } from "react-router-dom";
import { Nav, Row, Col, Image, Container } from "react-bootstrap";
import footerLogo from '../../assets/images/IICAcademylogoBW.png';
import sextant from '../../assets/images/sextant-1.png';
const S8BFooterSection = () => {
    const handleNavLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    return(
        <footer className="bg_footer" id="contactUs">
            <Container fluid>
                <Row>
                    <Col sm={4} className="mt-4">
                        <div className="px-xs-1 px-sm-4 py-1">
                            <div className="mb-3 p-2">
                            <Link to="/">
                                <Image src={footerLogo} width="224px"/>
                            </Link>
                            </div>
                            <p className="intro_para pb-2">
                                Do you have questions? Drop us a line at:<br/>
                                <a href="mailto:nauticalcartographer@iicacademy.com" class="white_text homenav">nauticalcartographer@iicacademy.com </a>
                            </p>
                        </div>
                    </Col>
                    <Col sm={4} className="mt-5">
                        <h3 className="text-white ps-xs-5 ps-sm-5">S-8B Marine Geospatial <br/> Information Program</h3>
                        <Nav className="ps-xs-5 ps-sm-5 flex-column">
                            <Nav.Link as={Link} to="/S8BProgram" className="text-white px-0 footerbtn" onClick={handleNavLinkClick}>Overview</Nav.Link>
                            <Nav.Link as={Link} to="/S8BprogramBreakdown" className="text-white px-0 footerbtn" onClick={handleNavLinkClick}>Program Schedule</Nav.Link>
                            <Nav.Link as={Link} to="/S8BapplyNow" className="text-white px-0 footerbtn" onClick={handleNavLinkClick}>Apply Now</Nav.Link>
                        </Nav>
                    </Col>
                    <Col sm={4}>
                        <Image src={sextant} className="footerImg" alt="" role="presentation"></Image>
                    </Col>
                </Row>
                <Row className="">
                    <div className="footer_copyright px-sm-5 px-3 py-4">
                        <p className="m-0 p-0 white_text homenav">© Copyright by <a href="https://www.iictechnologies.com/" target="_blank" rel="noopener noreferrer" className="white_text homenav footerbtn">IIC TECHNOLOGIES.</a> All Rights Reserved.</p>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

export default S8BFooterSection;
