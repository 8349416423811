import React, {useState, useRef, useEffect} from "react";
import S8BHeaderSection from "./S8BHeaderSection";
import S8BFooterSection from "./S8BFooterSection";
import { Image, Container, Row, Col, Form } from "react-bootstrap";
import image1 from '../../assets/images/Group 97_red.png';
import sextant1 from '../../assets/images/woman-using-sextant 1.png';
import image2 from '../../assets/images/map-photo.png';
import { toast } from "react-toastify";
import axios from "axios";
import ApplyNow from "../ApplyNow";

const S8BApplyNow = () => {
   /*  const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const mailingAddressRef = useRef(null);
    const emailAddressRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const nameofInstitutionRef = useRef(null);
    const dateRef = useRef(null);
    const degreeRef = useRef(null);
    const [showDateInput, setShowDateInput] = useState(true); 

    const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      mailingAddress: "",
      emailAddress: "",
      phoneNumber: "",
      nameofInstitution: "",
      date: "",
      degree: "",
      programDate: "",
      progressCheck: "",
    });
  
    const [errors, setErrors] = useState({
      firstName: "",
      lastName: "",
      mailingAddress: "",
      emailAddress: "",
      phoneNumber: "",
      nameofInstitution: "",
      date: "",
      degree: "",
      programDate: "",
      progressCheck: "",
    });

    const emailReg = /^([A-Za-z0-9_.-]+)@([A-Za-z0-9_\-]+)\.([A-Za-z]{2,4})(\.[A-Za-z]{2,4})?$/;
    const firstNameReg = /^[A-Za-z]*$/;
    const lastNameReg = /^[A-Za-z ]*$/;
    const institutionReg = /^[A-Za-z ]*$/;
    const degreeReg = /^[A-Za-z ]*$/;
   
  const handleValidation = () => {
    let formIsValid = true;
    const newErrors = { ...errors };

    if (!formData.firstName.trim()) {
        newErrors.firstName = "First name is required.";
        formIsValid = false;
    } else if (!firstNameReg.test(formData.firstName.trim())) {
      //else if (!formData.firstName.match(/^[a-zA-Z]*$/)) {
        newErrors.firstName = "Please enter alphabets only for first name.";
        formIsValid = false;
    }

    if (!formData.lastName.trim()) {
        newErrors.lastName = "Last name is required.";
        formIsValid = false;
    } else if (!lastNameReg.test(formData.lastName.trim())) {
        newErrors.lastName = "Please enter alphabets only for last name.";
        formIsValid = false;
    }

    if (!formData.mailingAddress) {
        newErrors.mailingAddress = "Mailing address is required.";
        formIsValid = false;
    }

    if (!formData.emailAddress) {
        newErrors.emailAddress = "Email address is required.";
        formIsValid = false;
    } else if (!emailReg.test(formData.emailAddress.trim())) {
        newErrors.emailAddress = "Invalid email address format.";
        formIsValid = false;
    }

    if (!formData.phoneNumber) {
        newErrors.phoneNumber = "Phone number is required.";
        formIsValid = false;
    }

    if (!formData.nameofInstitution) {
        newErrors.nameofInstitution = "Name of institution is required.";
        formIsValid = false;
    } else if (!institutionReg.test(formData.nameofInstitution.trim())) {
      newErrors.nameofInstitution = "Please enter alphabets only for name of institution.";
      formIsValid = false;
    }

    if (formData.progressCheck === "" || formData.progressCheck === undefined) {
        newErrors.progressCheck = "Please select in progress status.";
        formIsValid = false;
        
    } else {
        if (formData.progressCheck === "No") {
            formData.date = "N/A";
        } else if (formData.progressCheck === "Yes" && !formData.date) {
            newErrors.date = "Please select a date.";
            formIsValid = false;
        }
    }

    if (!formData.degree) {
        newErrors.degree = "Degree is required.";
        formIsValid = false;
    } else if (!degreeReg.test(formData.degree.trim())) {
        newErrors.degree = "Please enter alphabets only for degree.";
        formIsValid = false;
    }

    if (formData.programDate === "" || formData.programDate === undefined) {
        newErrors.programDate = "Please select program preferences.";
        formIsValid = false;
    }
    if (!formIsValid) {
        // If form is not valid, focus on the first invalid field
        if (!formData.firstName.trim()) {
            firstNameRef.current.focus();
        } else if (!formData.lastName.trim()) {
            lastNameRef.current.focus();
        } else if (!formData.mailingAddress) {
            mailingAddressRef.current.focus();
        } else if (!formData.emailAddress) {
            emailAddressRef.current.focus();
        } else if (!formData.phoneNumber) {
            phoneNumberRef.current.focus();
        } else if (!formData.nameofInstitution) {
            nameofInstitutionRef.current.focus();
        } else if (formData.progressCheck === "Yes" && !formData.date) {
            dateRef.current.focus();
        } else if (!formData.degree) {
            degreeRef.current.focus();
        }
    }
    setErrors(newErrors);
    return formIsValid;
   
};


    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === "checkbox" ? checked : value;
  
      setFormData({
        ...formData,
        [name]: newValue,
      });
  
      if (name === "progressCheck") {
        setShowDateInput(value === "Yes");
      }
      // Clear validation error when user starts typing
      setErrors({
        ...errors,
        [name]: "",
        
      });
    };
    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        document.getElementById('dateInput').setAttribute('min', today);
      }, []);
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!handleValidation()) {
        return;
      }
      console.log(formData);
                var text = `First Name: ${formData.firstName}\n` +
               `Last Name: ${formData.lastName}\n` +
               `Mailing Address: ${formData.mailingAddress}\n` +
               `Email Address: ${formData.emailAddress}\n` +
               `Phone Number: ${formData.phoneNumber}\n` +
               `Name of Institution: ${formData.nameofInstitution}\n` +
               `Planned Graduation Date: ${formData.date}\n` +
               `Degree: ${formData.degree}\n` +
               `Start Program on September: ${formData.programDate}\n` +
               `Progress: ${formData.progressCheck}`;

       console.log(text);     
  const from = "nauticalcartographer@iicacademy.com";
  const subject = "Registration Acknowledgment";
  const data_js = {
      access_token: "gmt4k4qzmgpszubpj74jgzh7", 
      from: from,
      subject: subject,
      text: text,
  };

  try {
      const response = await axios.post("https://postmail.invotes.com/send", data_js, {
          headers: {
              'Content-Type': 'application/json'
          }
      });

      // Handling the response
      if (response.status === 200) {
          toast('Email sent successfully:', response.data);
          setFormData({
            firstName: "",
            lastName: "",
            mailingAddress: "",
            emailAddress: "",
            phoneNumber: "",
            nameofInstitution: "",
            date: "",
            degree: "",
            programDate: "",
            progressCheck: "",
        });
      } else {
          toast('Failed to send email. Status:', response.status);
         
      }
  } catch (error) {
      toast('Error sending email:', error);
     
  } 
    }; */
  
    return(
        <>
        <S8BHeaderSection/>
	    <Container>
			<Row>
				<Col sm={3} className="d-flex align-items-center justify-content-center">
					<Image src={image1} alt="" className="img-fluid mt-3"></Image>
				</Col>
				<Col sm={7}>
					<div className="mx-auto my-3 py-3 black_text text-center margin_0">
						<h1 className="text-center applynow">Apply Now</h1>
						<p className="p-3 margin_0 padding_0">This is the first step in the registration process. We want to hear from you. There is no financial commitment at this point.</p>
					</div>
				</Col>
				<Col sm={2}>
					&nbsp;
				</Col>
			</Row>
		</Container>
		<Container>
			<Row>
				<Col sm={6} className="d-flex align-items-center">
				    <div className="py-4">
					<h2 className="mb-3 black_text all_main_heads">Course Pre-Requisites</h2>
					<p className="black_text pr-2">The following skills, education and experience are  for program entry:</p>
					<ul>
						<li>
						A high school diploma plus two years of additional education focused on GIS, geography,
						 physics, mathematics, computer science or IT from a technical college, 
						 institute or university
						 </li>
						<li>
						English proficiency
						</li>
					</ul>

					<p className="black_text pr-2 margin_0">If you are interested in attending this course but do not have the 
					requirements listed above, please contact our friendly team at 
					<a href="mailto:nauticalcartographer@iicacademy.com"> nauticalcartographer@iicacademy.com </a>and we can discuss how these could be achieved.</p>
					
					</div>
				</Col>
				<Col sm={6}>
					<div className="py-4">
					<Image src={sextant1} alt="" className="img-fluid"></Image>
					</div>
				</Col>
			</Row>
		</Container>
<ApplyNow/>
       {/*  <Container fluid>
            <Row className="bg_skyblue">
                <Col>
                    <div className="mx-auto pt-5 pb-3 black_text text-center">
                        <h2 className="text-center all_main_heads">EXCITED? INTERESTED?</h2>
                        <p className="p-3 margin_0">To get more details on the registration and pricing, please complete the brief form below.</p>
                    </div>
                    <Container>
                    <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={6}>
                            <div className="px-4 pb-4 padding_0">
                            <h4 className="black_text my-3 form_heads">General</h4>
                            <Form.Group className="mb-3" controlId='firstName'>
                                <Form.Label>First Name*:</Form.Label>
                                <Form.Control 
                                type="text" 
                                className="form-control" 
                                placeholder="Enter first name" 
                                name="firstName" 
                                value={formData.firstName}
                                onChange={handleInputChange} ref={firstNameRef}/>
                                <small id='firstNameHelp' className='text-danger form-text'>
                                    {errors.firstName}
                                </small>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>Last Name*:</Form.Label>
                            <Form.Control 
                                type="text" 
                                className="form-control" 
                                placeholder="Enter last name" 
                                value={formData.lastName}
                                onChange={handleInputChange}
                                ref={lastNameRef}
                                name="lastName" />
                                <small id='lastNameHelp' className='text-danger form-text'>
                                    {errors.lastName}
                                </small>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="mailingAddress">
                            <Form.Label>Mailing Address*:</Form.Label>
                            <textarea 
                                className="form-control" 
                                name="mailingAddress"
                                onChange={handleInputChange}
                                value={formData.mailingAddress}
                                ref={mailingAddressRef}
                                ></textarea>
                                <small id='mailingAddressHelp' className='text-danger form-text'>
                                    {errors.mailingAddress}
                                </small>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="emailAddress">
                            <Form.Label>Email Address*:</Form.Label>
                            <Form.Control 
                                type="email" 
                                className="form-control" 
                                placeholder="Enter email" 
                                value={formData.emailAddress}
                                onChange={handleInputChange}
                                ref={emailAddressRef}
                                name="emailAddress" />
                                <small id='emailAddressHelp' className='text-danger form-text'>
                                    {errors.emailAddress}
                                </small>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phoneNumber">
                            <Form.Label>Phone Number*:</Form.Label>
                            <Form.Control 
                                type="text" 
                                className="form-control" 
                                placeholder="Enter phone number" 
                                value={formData.phoneNumber}  
                                onChange={handleInputChange}
                                ref={phoneNumberRef}
                                name="phoneNumber" />
                                <small id='phoneNumberHelp' className='text-danger form-text'>
                                    {errors.phoneNumber}
                                </small>
                        </Form.Group>
                        
                        <Image src={image2} className="img-fluid" alt=""></Image>
                        </div>
                    </Col>
                    <Col sm={6}>
                    <div className="px-4 pb-4 padding_0">
                        <h4 className="black_text my-3 form_heads">Education</h4>
                        
                            <Form.Group className="mb-3" controlId="nameofInstitution">
                            <Form.Label>Name of Institution*:</Form.Label>
                            <Form.Control 
                                type="text" 
                                className="form-control" 
                                placeholder="Enter name of Institution" 
                                value={formData.nameofInstitution}
                                onChange={handleInputChange}
                                ref={nameofInstitutionRef}
                                name="nameofInstitution" />
                                <small id='nameofInstitutionHelp' className='text-danger form-text'>
                                    {errors.nameofInstitution}
                                </small>
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Label>In Progress*:</Form.Label>
                            <Form.Check
                                label="Yes"
                                type="radio"
                                name="progressCheck" value="Yes" id="inprogressYes"
                                checked={formData.progressCheck === 'Yes'}
                                onChange={handleInputChange}
                                
                            >     
                        </Form.Check>
                        <Form.Check
                                label="No"
                                type="radio"
                                name="progressCheck" value="No" id="inprogressNo"
                                checked={formData.progressCheck === 'No'}
                                onChange={handleInputChange}
                                
                            >     
                        </Form.Check>
                            <small id='progressCheckHelp' className='text-danger form-text'>
                                {errors.progressCheck}
                            </small>
                            </Form.Group>
                            {showDateInput && (
                            <Form.Group id="planned_graduationdiv" className="mb-3" controlId="date">
                                <Form.Label>If Yes, planned graduation date*:</Form.Label>
                                <Form.Control 
                                type="date" 
                                className="form-control" 
                                name="date" 
                                value={formData.date}
                                ref={dateRef}
                                onChange={handleInputChange}
                                id="dateInput"
                                />
                                <small id='dateHelp' className='text-danger form-text'>
                                    {errors.date}
                                </small>
                            </Form.Group>
                            )}
                            <Form.Group className="mb-3" controlId="degree">
                                <Form.Label>Degree*:</Form.Label>
                                <Form.Control 
                                type="text" 
            t                  className="form-control" 
                                placeholder="Enter degree" 
                                onChange={handleInputChange}
                                ref={degreeRef}
                                value={formData.degree}
                                name="degree" />
                                <small id='degreeHelp' className='text-danger form-text'>
                                    {errors.degree}
                                </small>
                            </Form.Group>
                            
                            <h4 className="black_text mt-5 mb-3 form_heads">Preferences</h4>
                            <Form.Group className="mb-3">
                            <Form.Label>Can you start the program on ..September?*:</Form.Label>
                            <Form.Check
                                label="Yes"
                                type="radio"
                                name="programDate" 
                                value="Yes" 
                                id="startprogramYes"
                                checked={formData.programDate === 'Yes'}
                                onChange={handleInputChange}
                            >
                            
                        </Form.Check>
                        <Form.Check
                                label="No"
                                type="radio"
                                name="programDate" 
                                value="No" 
                                id="startprogramNo"
                                checked={formData.programDate === 'No'}
                                onChange={handleInputChange}
                            >
                            </Form.Check>
                            <small id='programDateHelp' className='text-danger form-text'>
                                {errors.programDate}
                            </small>
                            </Form.Group>
                        
                        <button className="redbtn border-0 my-5" type="submit">Submit Request</button>
                        </div>
                    </Col>
                        
                    </Row>
                    </Form>
                    </Container>
                </Col>
                <div className="lodaer_mask" id="loaderMask">
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                </div>
            </Row>
        </Container> */}
            <S8BFooterSection/>
        </>
    )
}
export default S8BApplyNow;