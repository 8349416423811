import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Image, Row, Col } from "react-bootstrap";
import logo from '../../assets/images/Academylogo300dpi.png';
import HomeIcon from'../../assets/images/home.png';

const S5BHeaderSection = () => {
    return(
      <header className="row">
      <div className="bg_banner_sec">
      <Container fluid className=" bg-white">
        <Row>
          <Col>
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand as={Link} to="/"> <Image src={logo} alt="IIC Academy" className="p-2" style={{width: '250px'}}/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto navutility">
                    <Nav.Item className="px-4"><Nav.Link as={Link} to="/S5BProgram" className="black_text">Overview</Nav.Link></Nav.Item>
                    <Nav.Item className="px-4"><Nav.Link as={Link} to="/S5BprogramBreakdown" className="black_text">Program Breakdown</Nav.Link></Nav.Item>
                    <Nav.Item className="px-4"><Nav.Link href="#contactUs" className="black_text">Contact us</Nav.Link></Nav.Item>
                    <Nav.Item className="px-4"><button className="redbtn learnmore"><Nav.Link as={Link} to="/S5BapplyNow" className="p-0 learnmore">Apply Now</Nav.Link></button></Nav.Item>
                    <Nav.Item className="px-4"><Nav.Link as={Link} to="/" className="black_text"><Image width="43px" alt="home" src={HomeIcon}></Image></Nav.Link></Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
          </Col>
        </Row>
        </Container>
       
      </div>
    </header>	
    )
}
export default S5BHeaderSection;