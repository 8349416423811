import React from "react";
import './HeaderSection.css';
import { Link } from "react-router-dom";
import { Navbar, Nav, Image, Container } from "react-bootstrap";
import logo from '../../assets/images/Academylogo300dpi.png';
const HeaderSection = () => {
    return(
      <Container fluid>
        <header>
           <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand as={Link} to='/'> <Image src={logo} alt="IIC Academy" className="p-2" style={{width: '250px',}}></Image></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto navutility">
                    <Nav.Item className="px-4"><Nav.Link as={Link} to="/S5BProgram" className="black_text">S-5B Hydrographic Surveying</Nav.Link></Nav.Item>
                    <Nav.Item className="px-4"><Nav.Link as={Link} to="/S8BProgram" className="black_text">S-8B Marine Geospatial Information</Nav.Link></Nav.Item>
                    <Nav.Item className="px-4"><button className="redbtn learnmore"><Nav.Link href="https://iicacademy.blackboard.com" className="p-0 learnmore">Student Login</Nav.Link></button></Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
    </header>
    </Container>
    )
}
export default HeaderSection;