import React from "react";
import comapssImg from '../../assets/images/compass 1.png';
import { Container, Row, Col, Image, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

const S8BHeroSection = () => {
    return(
      <div className="bg_banner_sec">
        <Container>
          <Row>
            <Col>
              <div className="intro py-5 my-5 margin_0">
                <h1 className="intro_heading py-2">
                Map your future, become a Nautical Cartographer!
                </h1>
                <p className="intro_para pb-2">
                Do you enjoy mapping and have a love for the oceans?  Our recognized S-8 Category B Marine Geospatial Information Program is now available online.
                </p>
                <button className="redbtn learnmore"><Nav.Link as={Link} to="/S8BprogramBreakdown">Learn More</Nav.Link></button>
              </div>
            </Col>
            <Col>
              <Row className="intro py-5 my-5 space_bottom_4">
                <Col sm={12}>
                <Image className="mx-auto py-2 d-block" src={comapssImg}></Image>
                </Col>
                <Col sm={12}>
                <div className="compass_bottom ps-5 ms-5">
                <p className="red_text m-0">GLOBAL DELIVERY</p>
                <p className="white_text m-0">Marine Geospatial Information Program</p>
                </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
}
export default S8BHeroSection;