import React from "react";
import S5BHeaderSection from "./S5BHeaderSection";
import S5BFooterSection from "./S5BFooterSection";
import S5BHeroSection from "./S5BHeroSection";
import { Container, Image, Row, Col, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import SkyPhoto from '../../assets/images/sky-photo.png';
import icon1 from '../../assets/images/icons/regprogram.png';
import icon2 from '../../assets/images/icons/learnbest.png';
import icon3 from '../../assets/images/icons/NCProduction.png';
import icon4 from '../../assets/images/icons/stayhome.png';

const S5BOverviewPage = () => {
    return(
        <>
            <S5BHeaderSection/>
            <S5BHeroSection/>
            <Container>
			<Row className="pt-4">
				<Col sm={6} className="d-flex align-items-center">
				<div className="py-4">
					<h2 className="mb-3 black_text all_main_heads">Program Summary</h2>
					<p className="black_text pr-2">
						The S-5 Category B program prepares candidates with the theoretical and practical competencies necessary to carry out the planning and implementation of hydrographic surveys effectively. The IIC Academy is committed to providing a quality learning environment through a modular program that follows the IHO IBSC S-5 Category B Standards of Competence for Hydrographic Surveyors. The program is recognized by the FIG/IHO/ICA International Board on Standards of Competence for Hydrographic Surveyors and is designed to maximize the advantages of online delivery. 
					</p>
					<div className="my-2"><button className="redbtn_see"><Nav.Link as={Link} to="/S5BapplyNow">Apply Now</Nav.Link></button></div>
				 </div>
				</Col>
				<Col sm={6}>
					<div className="py-4">
					<Image src={SkyPhoto} className="img-fluid"></Image>
					</div>
				</Col>
			</Row>

		<Row className="why">
			<div className="col">
			<div className="mx-auto my-5">
				<h2 className="why_enroll text-center all_main_heads h1">Why Enrol?</h2>
			</div>
			</div>
		</Row>
		<Row>
			<div className="col">
				<div className="card border-0">
					<div className="card-header border-0 bg-white enrol_pad">
						<div className="d-flex align-items-center">
						<Image src={icon1} className="mob_enrol_img"></Image>
						<h4 className="black_text m-0 px-3 mob_enrol_title">Recognized Program</h4>
						</div>
					</div>
					<div className="card-body pt-0 enrol_pad">
						<p className="black_text">
							Recognized by the FIG/IHO/ICA International Board on Standards of Competence for Hydrographic Surveyors and Nautical Cartographers.
						</p>
					</div>
				</div>
			</div>
			<div className="col">
				<div className="card border-0">
					<div className="card-header border-0 bg-white enrol_pad">
						<div className="d-flex align-items-center">
						<Image src={icon3} className="mob_enrol_img"></Image>
						<h4 className="black_text m-0 px-3 mob_enrol_title">Certification as Hydrographic Surveyor</h4>
						</div>
					</div>
					<div className="card-body pt-0 enrol_pad">
						<p className="black_text">
							Program prepares candidates with the theoretical knowledge, practical competencies and confidence for certification as an S5 Category B Hydrographic Surveyor.
						</p>
					</div>
				</div>
			</div>
		</Row>
		<Row>
			<div className="col-12 col-sm-6">
				<div className="card border-0">
					<div className="card-header border-0 bg-white enrol_pad">
						<div className="d-flex align-items-center">
						<Image src={icon2} className="mob_enrol_img"></Image>
						<h4 className="black_text m-0 px-3 mob_enrol_title">Learn from the Best</h4>
						</div>
					</div>
					<div className="card-body pt-0 enrol_pad">
						<p className="black_text">
							Our dedicated instructors are recognized professionals from the hydrographic industry, not only experienced in the S-5B topics, but also advocates for teaching approaches, engaging and supporting the students.
						</p>
					</div>
				</div>
			</div>
			<div className="col-12 col-sm-6">
				<div className="card border-0">
					<div className="card-header border-0 bg-white enrol_pad">
						<div className="d-flex align-items-center">
						<Image src={icon4} className="mob_enrol_img"></Image>
						<h4 className="black_text m-0 px-3 mob_enrol_title">Stay Home Longer!</h4>
						</div>
					</div>
					<div className="card-body pt-0 enrol_pad">
						<p className="black_text">
							The program delivers all of its theoretical components online via remote learning and instructor led lectures and exercises, allowing for a shorter period of practicals and final period on site.
						</p>
					</div>
				</div>
			</div>
		</Row>
        </Container>
        <Container fluid>
	<Row className="bg_blue bg_img">
		<Col>
			<Container>
				<Row>
                    <Col sm={2}>&nbsp;</Col>
                    <Col sm={8}>
					<div className="mx-auto my-5 py-5 white_text text-center">
						<h2 className="text-center all_main_heads h1">Starts Sept. 2<sup>nd</sup>, 2024</h2>
						<p className="p-3">The programme is designed to deliver all theory and exercises online, focused on developing a student’s theoretical and practical knowledge on hydrographic surveying. The programme completes with the practicals and Final Field Project on site, at a designated location. </p>
						<button className="redbtn_see"><Nav.Link as={Link} to="/S5BprogramBreakdown">See Schedule</Nav.Link></button>
					</div>
                    </Col>
                    <Col sm={2}>&nbsp;</Col>
				</Row>
			</Container>
		</Col>
		
	</Row>
	<div className="lodaer_mask" id="loaderMask">
		<div className="lds-facebook"><div></div><div></div><div></div></div>
	</div>
    </Container>

            <S5BFooterSection/>
        </>
    )
}
export default S5BOverviewPage;