import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
const ApplyNow = () => {
  return (
    <Container fluid className='px-0'>
        <iframe src="https://forms.monday.com/forms/embed/57c82fe5e44a6cf819c3b9f3193172cf?r=use1" width="100%" height="800" style={{border: '0', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)'}} title="ApplyNow Form"></iframe>
    </Container>
  )
}

export default ApplyNow